import * as React from 'react'

import {
  btnCadastrese
} from '../components/styles/Buttons.styles'

const CadastreseBtn = ({ children }) => (
  <button css={btnCadastrese}>{ children }</button>
)

export default CadastreseBtn
