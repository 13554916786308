import { css } from '@emotion/react'
import ArrowL from '../images/arrow-l.svg'
import ArrowR from '../images/arrow-r.svg'

export const globalStyles = css`
	html, body, div, span, applet, object, iframe,
	h1, h2, h3, h4, h5, h6, p, blockquote, pre,
	a, abbr, acronym, address, big, cite, code,
	del, dfn, em, img, ins, kbd, q, s, samp,
	small, strike, strong, sub, sup, tt, var,
	b, u, i, center,
	dl, dt, dd, ol, ul, li,
	fieldset, form, label, legend,
	table, caption, tbody, tfoot, thead, tr, th, td,
	article, aside, canvas, details, embed, 
	figure, figcaption, footer, header, hgroup, 
	menu, nav, output, ruby, section, summary,
	time, mark, audio, video {
		margin: 0;
		padding: 0;
		border: 0;
		font-size: 100%;
		font: inherit;
		vertical-align: baseline;
	}
	/* HTML5 display-role reset for older browsers */
	article, aside, details, figcaption, figure, 
	footer, header, hgroup, menu, nav, section {
		display: block;
	}
	body {
		line-height: 1;
		margin: 0 !important;
	}
	html {
		font-family: 'Barlow Semi Condensed', sans-serif;
	}
	ol, ul {
		list-style: none;
	}
	blockquote, q {
		quotes: none;
	}
	blockquote:before, blockquote:after,
	q:before, q:after {
		content: '';
		content: none;
	}
	table {
		border-collapse: collapse;
		border-spacing: 0;
	}
	a {
		text-decoration: none;
	}
	
	.react-responsive-modal-modal {
		background: transparent;
    box-shadow: none;
	}

	.slick-next:before {
		background: url(${ArrowR}) no-repeat;
	}
	.slick-prev:before {
		background: url(${ArrowL}) no-repeat;
	}
	.slick-prev:before, .slick-next:before {
		font-size: 80px;
		color: transparent !important;
	}
	.slick-prev {
		left: -60px;
	}

	.slick-dots li button:before {
		font-size: 12px;
		top: 24px;
	}

	.slick-dots li.slick-active button:before {
    color: #3E6CFF;
}

	.react-responsive-modal-closeButton {
		background-color: #FFFAA9;
	}
`