import { css } from "@emotion/react"

export const btnStartNow = css`
  font-family: 'Antonio', sans-serif;
  width: 227px;
  height: 40px;
  background: #3E6CFF;
  font-size: 25.0667px;
  line-height: 36px;
  display: flex;
  align-items: center;
  letter-spacing: -0.05em;
  color: #EAEAEA;
  outline: none;
  border: none;
  justify-content: center;
  box-shadow: 8px 8px 0 0 #1E1E1E;
  cursor: pointer;
  :active {
    background: #FFFAA9;
    color: #1E1E1E;
  }
`

export const btnStartNow2 = css`
  width: 227px;
  height: 40px;
  background: #EAEAEA;
  font-size: 25.0667px;
  line-height: 36px;
  display: flex;
  align-items: center;
  letter-spacing: -0.05em;
  color: #1E1E1E;
  outline: none;
  border: none;
  justify-content: center;
  box-shadow: 8px 8px 0 0 #1E1E1E;
  cursor: pointer;
  :active {
    background: #1E1E1E;
    color: #EAEAEA;
  }
`

export const btnCadastrese = css`
  font-family: 'Antonio', sans-serif;
  width: 150px;
  height: 40px;
  background: #FFFAA9;
  font-size: 25.0667px;
  line-height: 36px;
  display: flex;
  align-items: center;
  letter-spacing: -0.05em;
  color: #1E1E1E;
  outline: none;
  border: none;
  justify-content: center;
  box-shadow: 8px 8px 0 0 #1E1E1E;
  cursor: pointer;
  :active {
    background: #EAEAEA;
    color: #3E6CFF;
  }
`

export const btnCorretor = css`
  font-family: 'Antonio', sans-serif;
  width: 280px;
  height: 40px;
  background: #3E6CFF;
  font-size: 25.0667px;
  line-height: 36px;
  display: flex;
  align-items: center;
  letter-spacing: -0.05em;
  color: #EAEAEA;
  outline: none;
  border: none;
  justify-content: center;
  box-shadow: 8px 8px 0 0 #EAEAEA;
  cursor: pointer;
  :active {
    background: #FFFAA9;
    color: #1E1E1E;
  }
`

export const btnPreCadastro = css`
  font-family: 'Antonio', sans-serif;
  width: 290px;
  height: 40px;
  background: #3E6CFF;
  font-size: 25.0667px;
  line-height: 36px;
  display: flex;
  align-items: center;
  letter-spacing: -0.05em;
  color: #EAEAEA;
  outline: none;
  border: none;
  justify-content: center;
  box-shadow: 8px 8px 0 0 #1E1E1E;
  cursor: pointer;
  :active {
    background: #FFFAA9;
    color: #1E1E1E;
  }
`